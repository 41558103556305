import { useContext, useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Skeleton, Tooltip, Typography } from "@mui/material";
import {
  HiOutlineCheck,
  HiOutlineClipboard,
  HiOutlinePlus,
  HiOutlineTrash,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { Autocomplete } from "../components/Autocomplete";
import api from "../components/api/api";
import { Amministratore } from "../utility/useAmministraotre";
import moment from "moment";
import { toast } from "react-toastify";
import { ModalDelete } from "../components/ModalDelete";

function splitString(str, chunkSize) {
  var chunks = [];
  for (var i = 0; i < str.length; i += chunkSize) {
    chunks.push(str.substring(i, i + chunkSize));
  }
  return chunks;
}

const columns = [
  {
    field: "riferimento",
    headerName: "Sinistro",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      let riferimento = params.row?.riferimento;
      let data = params.row?.data
        ? moment(params.row?.data).format("YY") + " "
        : "";
      let condominio =
        params.row?.condominio !== ""
          ? params.row?.condominio + " "
          : "Condominio ";
      let danno = params.row?.danno !== "" ? params.row?.danno + " " : "Danno ";
      let persone = params.row?.persone ? params.row?.persone : "";
      return (
        <>
          {riferimento +
            "/" +
            data +
            " " +
            condominio +
            " " +
            danno +
            " " +
            persone}
        </>
      );
    },
  },
  {
    field: "testo",
    headerName: "Testo",
    width: 302,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      var resultArray = splitString(params.row.testo, 50);
      return (
        <Tooltip
          className="cursor-pointer "
          title={<div className="text-sm">{params.row.testo}</div>}
        >
          {resultArray.map((el) => (
            <Typography
              fontSize={14}
              lineHeight={1}
              className="text-left text-sx"
            >
              {el}
            </Typography>
          ))}
        </Tooltip>
      );
    },
  },
];

export const Promemoria = () => {
  const [loading, setLoading] = useState(false);
  const [amministratore, setAmministratore] = useContext(Amministratore);
  const navigate = useNavigate();
  const [promemoria, setPromemoria] = useState([]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() =>
                navigate("/Nuova-Promemoria", {
                  state: { promemoria: promemoria },
                })
              }
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      let responseAmministratori;
      if (amministratore !== 0) {
        responseAmministratori = await api.get("promemoria/" + amministratore);
      } else {
        responseAmministratori = await api.get("promemoria");
      }
      setPromemoria(responseAmministratori.data.data);
      setLoading(false);
    })();
  }, [amministratore]);

  return (
    <div className="page-container ">
      <div className="w-full container-box h-full">
        <div className=""></div>
        {!loading ? (
          <DataGridPro
          ignoreDiacritics
            rows={promemoria}
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbar }}
            onRowClick={(row) => {
              navigate("/Dettaglio-Promemoria", {
                state: { promemoria: row.row, allPromemoria: promemoria },
              });
            }}
            disableRowSelectionOnClick
          />
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const NuovaPromemoria = () => {
  const location = useLocation();
  const [amministratore, setAmministratore] = useContext(Amministratore);
  const [sinistri, setSinistri] = useState([]);
  const [promemoria, setPromemoria] = useState({
    id_sinistro: "",
    testo: "",
  });
  const handleChange = (e) => {
    setPromemoria({
      ...promemoria,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    if (promemoria.id_sinistro !== "") {
      try {
        await api.post("promemoria", {
          promemoria: promemoria,
        });
        toast.success("Salvataggio completato!");
      } catch (error) {
        toast.error("Errore durante la modifica. Riprova!");
      }
    } else {
      toast.error("Selezionare un sinistro!");
    }
  };

  useEffect(() => {
    (async () => {
      let responseSinistri;
      if (amministratore !== 0) {
        const urlParams = new URLSearchParams();
        urlParams.append("noYear", true);
        urlParams.append("id", amministratore);
        responseSinistri = await api.get("sinistri", { params: urlParams });
      } else {
        const urlParams = new URLSearchParams();
        urlParams.append("noYear", true);
        responseSinistri = await api.get("sinistri/all", { params: urlParams });
      }
      /* 
      if (amministratore !== 0) {
        responseSinistri = await api.get("sinistri/" + amministratore);
      } else {
        responseSinistri = await api.get("sinistri");
      }
      //setSinistri(responseSinistri.data.data);*/
      const differenceSinistri = responseSinistri.data.data?.filter(
        (sinistro) =>
          location.state?.promemoria
            ?.map((prom) => prom.id_sinistro)
            .indexOf(sinistro.id) === -1
      );
      setSinistri(
        differenceSinistri?.map((el) => {
          let riferimento = el?.riferimento;
          let data = el?.data ? moment(el?.data).format("YY") + " " : "";
          let condominio =
            el?.condominio !== "" ? el?.condominio + " " : "Condominio ";
          let danno = el?.danno !== "" ? el?.danno + " " : "Danno ";
          let persone = el?.persone ? el?.persone : "";
          return {
            ...el,
            sinistro:
              riferimento +
              "/" +
              data +
              " " +
              condominio +
              " " +
              danno +
              " " +
              persone,
          };
        })
      );
    })();
  }, []);

  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            {sinistri && <Autocomplete
              suggestions={sinistri}
              fields={["sinistro"]}
              keyField="id"
              value={promemoria?.id_sinistro}
              id="nome"
              label="Sinistro"
              name="nome"
              onChangeFunc={(e) => {
                setPromemoria({
                  ...promemoria,
                  id_sinistro: e,
                });
              }}
              icon={<HiOutlinePencilAlt className="text-2xl" />}
            />}
          </div>
          <div className="col-span-2">
            <span className="text-lg font-normal">Testo</span>
            <div className="relative">
              <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                <HiOutlineClipboard className="text-2xl" />
              </span>

              <textarea
                type="text"
                name="testo"
                className="input flex items-center"
                placeholder="Inserisci Appunti"
                onChange={handleChange}
                value={promemoria?.testo}
              />
            </div>
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const DettaglioPromemoria = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [amministratore, setAmministratore] = useContext(Amministratore);

  const [openDelete, setOpenDelete] = useState(false);
  const [sinistri, setSinistri] = useState([]);

  const [promemoria, setPromemoria] = useState(null);

  const handleChange = (e) => {
    setPromemoria({
      ...promemoria,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    try {
      await api.put("promemoria", {
        promemoria: promemoria,
      });
      toast.success("Modifica completata!");
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };
  const handleDelete = async () => {
    try {
      await api.delete("promemoria/" + promemoria?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };

  useEffect(() => {
    (async () => {
      setPromemoria(location.state?.promemoria);
      let responseSinistri;
      if (amministratore !== 0) {
        const urlParams = new URLSearchParams();
        urlParams.append("noYear", true);
        urlParams.append("id", amministratore);
        responseSinistri = await api.get("sinistri", { params: urlParams });
      } else {
        const urlParams = new URLSearchParams();
        urlParams.append("noYear", true);
        responseSinistri = await api.get("sinistri/all", { params: urlParams });
      }
      setSinistri(
        responseSinistri.data.data.map((el) => {
          let riferimento = el?.riferimento;
          let data = el?.data ? moment(el?.data).format("YY") + " " : "";
          let condominio =
            el?.condominio !== "" ? el?.condominio + " " : "Condominio ";
          let danno = el?.danno !== "" ? el?.danno + " " : "Danno ";
          let persone = el?.persone ? el?.persone : "";
          return {
            ...el,
            sinistro:
              riferimento +
              "/" +
              data +
              " " +
              condominio +
              " " +
              danno +
              " " +
              persone,
          };
        })
      );
    })();
  }, []);
  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            {sinistri && <Autocomplete
              disabled={true}
              suggestions={sinistri}
              fields={["sinistro"]}
              keyField="id"
              value={promemoria?.id_sinistro}
              id="nome"
              label="Sinistro"
              name="nome"
              onChangeFunc={(e) => {
                setPromemoria({
                  ...promemoria,
                  id_sinistro: e,
                });
              }}
              icon={<HiOutlinePencilAlt className="text-2xl" />}
            />}
          </div>
          <div className="col-span-2">
            <span className="text-lg font-normal">Testo</span>
            <div className="relative">
              <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                <HiOutlineClipboard className="text-2xl" />
              </span>

              <textarea
                type="text"
                name="testo"
                className="input flex items-center"
                placeholder="Inserisci Appunti"
                onChange={handleChange}
                value={promemoria?.testo}
              />
            </div>
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-red h-min group"
              onClick={() => setOpenDelete(true)}
            >
              <HiOutlineTrash className="icon-red" />
            </div>
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={promemoria?.testo}
        handleDelete={handleDelete}
      />
    </>
  );
};
