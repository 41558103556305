import { useContext, useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Skeleton, TextField, Tooltip } from "@mui/material";
import { FaCircle } from "react-icons/fa";
import {
  HiOutlineTrash,
  HiOutlineCheck,
  HiOutlineListBullet,
  HiOutlinePlus,
  HiOutlineUser,
  HiOutlineArrowPath,
  HiOutlineClipboard,
  HiOutlineUsers,
  HiOutlineDocumentText,
  HiOutlinePaperAirplane,
  HiOutlineBuildingOffice,
  HiOutlineCurrencyEuro,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/Input";
import { StatoFatturazione } from "../components/StatoFatturazione";
import { Amministratore } from "../utility/useAmministraotre";
import api from "../components/api/api";
import moment from "moment";
import { Modal } from "../components/Modal";
import { toast } from "react-toastify";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/it";
import { HiOutlineMail } from "react-icons/hi";
import { ModalXL } from "../components/ModalXL";
import { PiSignature } from "react-icons/pi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Checkbox } from "../components/Checkbox";
const columns = [
  /* {
    filterable: false,
    field: "Stato",
    headerName: "Stato",
    width: 80,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      let color = params.row.stato === "" ? "gray" : params.row.stato;
      let tooltip = "red";
      switch (params.row.stato) {
        case "red":
          tooltip = "Da pagare";
          break;
        case "green":
          tooltip = "Pagata";
          break;

        default:
          break;
      }
      return (
        <Tooltip title={tooltip}>
          <div className="w-full h-full flex items-center justify-center">
            <FaCircle className={`text-3xl`} color={color} />
          </div>
        </Tooltip>
      );
    },
  }, */ {
    field: "nome",
    headerName: "Impresa",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  /*   {
    field: "id_sinistro",
    headerName: "Sinistro",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
  }, */
];

const columnsCondominiali = [
  {
    field: "*",
    headerName: "Stato",
    width: 80,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <div className="w-full h-full flex items-center justify-center">
          <FaCircle
            className={`text-3xl`}
            color={params.row.stato === 1 ? "green" : "red"}
          />
        </div>
      );
    },
  },
  {
    field: "riferimento",
    headerName: "Sinistro",
    width: 350,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      let riferimento = params.row?.riferimento;
      let data = params.row?.data
        ? moment(params.row?.data).format("YY") + " "
        : "";
      let condominio =
        params.row?.condominio !== "" ? params.row?.condominio + " " : "";
      let danno = params.row?.danno !== "" ? params.row?.danno + " " : "";
      let persone = params.row?.persone ? params.row?.persone : "";
      return (
        <>
          {riferimento +
            "/" +
            data +
            " " +
            condominio +
            " " +
            danno +
            " " +
            persone}
        </>
      );
    },
  },
  {
    field: "intervento",
    headerName: "Intervento",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "fattura",
    headerName: "Importo",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "anno",
    headerName: "Anno",
    width: 70,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "n_fattura",
    headerName: "N° Fattura",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "dettaglio_pagamento",
    headerName: "Dettaglio Pagamento",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "codice_fattura",
    headerName: "Codice Fattura",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];
const columnsSinistri = [
  {
    field: "*",
    headerName: "Stato",
    width: 80,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <div className="w-full h-full flex items-center justify-center">
          <FaCircle
            className={`text-3xl`}
            color={params.row.stato === 1 ? "green" : "red"}
          />
        </div>
      );
    },
  },
  {
    field: "riferimento",
    headerName: "Sinistro",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      let riferimento = params.row?.riferimento;
      let data = params.row?.data
        ? moment(params.row?.data).format("YY") + " "
        : "";
      let condominio =
        params.row?.condominio !== "" ? params.row?.condominio + " " : "";
      let danno = params.row?.danno !== "" ? params.row?.danno + " " : "";
      let persone = params.row?.persone ? params.row?.persone : "";
      return (
        <>
          {riferimento +
            "/" +
            data +
            " " +
            condominio +
            " " +
            danno +
            " " +
            persone}
        </>
      );
    },
  },

  {
    field: "intervento",
    headerName: "Intervento",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "fattura",
    headerName: "Importo",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "anno",
    headerName: "Anno",
    width: 70,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "n_fattura",
    headerName: "N° Fattura",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "dettaglio_pagamento",
    headerName: "Dettaglio",
    width: 200,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "codice_fattura",
    headerName: "Codice Fattura",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

export const Pagamenti = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [condominiali, setCondominiali] = useState(false);
  const [da, setDa] = useState(moment(new Date()).subtract(3, "month"));
  const [a, setA] = useState(new Date());

  const [amministratore, setAmministratore] = useContext(Amministratore);
  const [pagamenti, setPagamenti] = useState([]);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedSinistri, setSelectedSinistri] = useState([]);
  const [open, setOpen] = useState(false);
  const [pagamentoMassivo, setPagamentoMassivo] = useState({
    stato: 0,
    codice_fattura: "",
    dettaglio_pagamento: "",
  });
  const [massive, setMassive] = useState(false);
  const [sinistroSelected, setSinistroSelected] = useState(null);

  const [openEmail, setOpenEmail] = useState(false);
  const [sending, setSending] = useState(false);
  const [mail, setMail] = useState({
    mittente: "",
    alias: "",
    password: "",
    destinatari: "",
    copia: "",
    copiaNascosta: "",
    oggetto: "",
    testo: "",
    firma: "",
  });

  const [table, setTable] = useState([]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="w-full flex justify-between">
          <div className="flex items-center flex-1 mb-2 gap-3">
            <GridToolbarQuickFilter placeholder="Cerca..." />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                inputFormat="yyyy-MM"
                views={["year", "month"]}
                label="Da"
                value={moment(da)}
                onChange={(e) => {
                  setDa(e);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" helperText={null} />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                inputFormat="yyyy-MM"
                views={["year", "month"]}
                label="A"
                value={moment(a)}
                onChange={(e) => {
                  setA(e);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" helperText={null} />
                )}
              />
            </LocalizationProvider>
            <div className="flex gap-2">
              <div className="icon-container-green group " onClick={loadData}>
                <HiOutlineArrowPath className="icon-green" />
              </div>
              <div
                className="icon-container-green group "
                onClick={loadCondominiali}
              >
                <HiOutlineBuildingOffice className="icon-green" />
              </div>
            </div>
          </div>
          <div className="flex gap-2 items-center">
            {selectedIds?.length > 0 && (
              <>
                <div className="icon-container-blue group" onClick={checkEmail}>
                  <HiOutlineMail className="icon-blue" />
                </div>
                <div
                  className="icon-container-blue group h-min"
                  onClick={() => {
                    setMassive(true);
                    setOpen(true);
                  }}
                >
                  <HiOutlineCurrencyEuro className="icon-blue" />
                </div>
              </>
            )}
          </div>
        </div>
      </GridToolbarContainer>
    );
  }
  const handleChangeMail = (e) => {
    setMail({ ...mail, [e.target.name]: e.target.value });
  };

  const handleInputChange = (e, rowIndex, field) => {
    const updatedTable = [...table];
    updatedTable[rowIndex][field] = e.target.value;
    setTable(updatedTable);
  };

  const checkEmail = async () => {
    //DATI BASE
    let responseTestoEmail = await api.get("tipologie_mail/" + "Pagamento");
    let responseAmministratori = await api.get("amministratori");
    let amm = responseAmministratori.data.data?.filter(
      (el) => el.id === amministratore
    )[0];
    let responseEmailAmministratori = await api.get(
      "amministratori/referente/" + amm?.id
    );
    let responseUtenti = await api.get(
      "utenti/" + sessionStorage.getItem("Username")
    );

    //DATI LAVORI IMPRESE
    let destinatari = responseEmailAmministratori.data.data
      ?.filter((responsabili) => responsabili.principale === 1)
      .map((res) => res.email);

    let tableData = selectedSinistri.map((lavoro) => {
      let imp = "";
      imp =
        (lavoro?.n_fattura ? "FATT " + lavoro?.n_fattura + " " : "") +
        (lavoro?.fattura ? lavoro?.fattura + "€" : "");
      let pers = lavoro?.persone ? lavoro?.persone?.split(",") : "";
      if (pers?.length >= 3) {
        pers = pers?.slice(0, 3);
      }
      pers =
        (lavoro?.danno ? lavoro?.danno + ", " : "") +
        (pers && pers?.join(", "));
      let row = {
        riferimento:
          lavoro?.riferimento +
          "/" +
          (lavoro?.data && moment(lavoro?.data).format("YY")),
        condominio: lavoro?.condominio,
        impresa: imp,
        persone: pers,
      };

      return row;
    });
    setTable(tableData);

    setMail({
      ...mail,
      mittente: amm?.email ? amm.email : "",
      alias: amm?.alias_check === 1 ? amm?.alias : "",
      password: amm?.password_email ? amm.password_email : "",
      destinatari: destinatari,
      copiaNascosta: responseUtenti.data?.data[0]?.copia_nascosta,
      firma: amm.firma ? amm.firma : "",
      oggetto: "Fatturazione",
      testo: responseTestoEmail.data.data[0]?.testo,
    });
    setOpenEmail(true);
  };

  const handleSendEmail = async () => {
    if (mail.destinatari) {
      setSending(true);
      try {
        await api.post("email/pagamenti/", {
          mittente: mail.mittente,
          alias: mail.alias,
          password: mail.password,
          destinatari: mail.destinatari,
          copia: mail?.copia,
          copiaNascosta: mail?.copiaNascosta,
          oggetto: mail.oggetto,
          testo: mail?.testo,
          table: table,
          firma: mail.firma,
          allegati: mail?.allegati ? [mail?.allegati] : "",
          utente: sessionStorage.getItem("Username"),
          tipologia: "Pagamento",
        });
        toast.success("Email inviata!");
      } catch (error) {
        toast.error("Errore durante l'invio!");
      }
      setSending(false);
    } else {
      toast.error("Campo Destinatari obbligatoro!");
    }
  };

  const loadCondominiali = async () => {
    setLoading(true);
    const urlParams = new URLSearchParams();
    urlParams.append("da", moment(da).format("YYYY-MM-DD"));
    urlParams.append("a", moment(a).format("YYYY-MM-DD"));
    urlParams.append("pagante", 2);
    let responseImprese;
    if (amministratore !== 0) {
      urlParams.append("id", amministratore);
      responseImprese = await api.get("pagamenti/condominiale", {
        params: urlParams,
      });
    } else {
      urlParams.append("all", true);
      responseImprese = await api.get("pagamenti/condominiale", {
        params: urlParams,
      });
    }
    setPagamenti(responseImprese.data.data);
    setCondominiali(true);
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
    setMassive(false);
    setSelectedIds([]);
    setPagamentoMassivo({
      stato: 0,
      codice_fattura: "",
    });
  };

  const handleSave = async () => {
    try {
      await api.put("imprese/pagamento", {
        impresa: sinistroSelected,
      });
      toast.success("Modifica completata!");
      handleClose();
      loadCondominiali();
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };

  const massiveUpdate = async () => {
    try {
      let response = await api.put("imprese/rel", {
        ids: selectedIds,
        pagamento: pagamentoMassivo,
      });
      toast.success(`Pagamenti aggiornati ${response.data.affectedRows}!`);
      handleClose();
      loadCondominiali();
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };

  const loadData = async () => {
    setLoading(true);
    setCondominiali(false);
    const urlParams = new URLSearchParams();
    urlParams.append("da", moment(da).format("YYYY-MM-DD"));
    urlParams.append("a", moment(a).format("YYYY-MM-DD"));
    urlParams.append("pagante", 1);
    let responseImprese;
    if (amministratore !== 0) {
      urlParams.append("id", amministratore);
      responseImprese = await api.get("pagamenti", { params: urlParams });
    } else {
      responseImprese = await api.get("pagamenti/all", { params: urlParams });
    }
    setPagamenti(responseImprese.data.data);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, [amministratore]);

  return (
    <div className="page-container ">
      <div className="w-full container-box h-full">
        {!loading ? (
          <>
            {!condominiali ? (
              <DataGridPro
                ignoreDiacritics
                rows={pagamenti}
                columns={columns}
                getRowId={(row) => row.id}
                slots={{ toolbar: CustomToolbar }}
                onRowClick={(row) => {
                  navigate("/Dettaglio-Pagamento", {
                    state: {
                      pagamento: row.row,
                      da: moment(da).format("YYYY-MM-DD"),
                      a: moment(a).format("YYYY-MM-DD"),
                    },
                  });
                }}
                disableRowSelectionOnClick
              />
            ) : (
              <DataGridPro
                ignoreDiacritics
                rows={pagamenti}
                columns={columnsCondominiali}
                getRowId={(row) => row.id}
                slots={{ toolbar: CustomToolbar }}
                onRowClick={(row) => {
                  setSinistroSelected({ ...row.row });
                  setOpen(true);
                  setMassive(false);
                }}
                checkboxSelection
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  setSelectedIds(ids);
                  const selectedRowData = pagamenti.filter((row) =>
                    selectedIDs.has(row.id)
                  );
                  setSelectedSinistri(selectedRowData);
                }}
                disableRowSelectionOnClick
              />
            )}
            <ModalXL open={openEmail} handleClose={() => setOpenEmail(false)}>
              <div className="grid grid-cols-2 gap-2 h-full px-2">
                <div className="col-span-2 text-2xl text-center">
                  Invio Pagamenti
                </div>
                <div className="col-span-2">
                  <Input
                    label={"Mittente"}
                    type="text"
                    name="mittente"
                    onChange={handleChangeMail}
                    value={mail?.mittente}
                    icon={<HiOutlineUser className="text-2xl" />}
                  />
                </div>
                {mail?.alias && (
                  <div className="col-span-2">
                    <Input
                      label={"Alias"}
                      type="text"
                      name="alias"
                      onChange={handleChangeMail}
                      value={mail?.alias}
                      icon={<HiOutlineUser className="text-2xl" />}
                    />
                  </div>
                )}
                <div className="col-span-2">
                  <Input
                    label={"Destinatari"}
                    type="text"
                    name="destinatari"
                    onChange={handleChangeMail}
                    value={mail?.destinatari}
                    icon={<HiOutlineUser className="text-2xl" />}
                  />
                </div>
                <div className="col-span-2">
                  <Input
                    label={"In Copia"}
                    type="text"
                    name="copia"
                    onChange={handleChangeMail}
                    value={mail?.copia}
                    icon={<HiOutlineUsers className="text-2xl" />}
                  />
                </div>
                <div className="col-span-2">
                  <Input
                    label={"In Copia Nascosta"}
                    type="text"
                    name="copiaNascosta"
                    onChange={handleChangeMail}
                    value={mail?.copiaNascosta}
                    icon={<HiOutlineUsers className="text-2xl" />}
                  />
                </div>
                <div className="col-span-2">
                  <Input
                    label={"Oggetto"}
                    type="text"
                    name="oggetto"
                    onChange={handleChangeMail}
                    value={mail?.oggetto}
                    icon={<HiOutlineUsers className="text-2xl" />}
                  />
                </div>
                <div className="col-span-2 ">
                  <span className="text-lg font-normal">Testo</span>
                  <div className="relative">
                    <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                      <HiOutlineDocumentText className="text-2xl" />
                    </span>

                    <textarea
                      rows={2}
                      type="text"
                      name="testo"
                      className="input flex items-center"
                      placeholder="Inserisci Testo"
                      onChange={handleChangeMail}
                      value={mail?.testo}
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <span className="text-lg font-normal">Tabella</span>
                  <table className="w-full ">
                    <thead>
                      <tr>
                        <th className="border border-black">Riferimento</th>
                        <th className="border border-black">Condominio</th>
                        <th className="border border-black">Impresa</th>
                        <th className="border border-black">Persone</th>
                      </tr>
                    </thead>
                    <tbody>
                      {table?.map((t, rowIndex) => (
                        <tr
                          key={rowIndex}
                          className="border border-black h-full"
                        >
                          <td className="border border-black">
                            <input
                              type="text"
                              className="text-center w-full"
                              value={t.riferimento}
                              onChange={(e) =>
                                handleInputChange(e, rowIndex, "riferimento")
                              }
                            />
                          </td>
                          <td className="border border-black">
                            <input
                              className="text-center w-full"
                              type="text"
                              value={t.condominio}
                              onChange={(e) =>
                                handleInputChange(e, rowIndex, "condominio")
                              }
                            />
                          </td>
                          <td className="border border-black">
                            <textarea
                              type="text"
                              className="text-center w-full"
                              value={t.impresa.replace("<br/>", " ")}
                              onChange={(e) =>
                                handleInputChange(e, rowIndex, "impresa")
                              }
                            />
                          </td>
                          <td className="border border-black">
                            <textarea
                              type="text"
                              className="text-center w-full"
                              value={t.persone}
                              onChange={(e) =>
                                handleInputChange(e, rowIndex, "persone")
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* <div className="col-span-2 ">
                  <span className="text-lg font-normal">Firma</span>
                  <div className="relative">
                    <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                      <PiSignature className="text-2xl" />
                    </span>

                    <textarea
                      rows={5}
                      type="text"
                      name="testo"
                      className="input flex items-center"
                      value={mail?.firma}
                    />
                  </div>
                </div> */}
                <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
                  {sending ? (
                    <div
                      className="icon-container-green group "
                      onClick={() => toast.warning("Invio in corso!")}
                    >
                      <AiOutlineLoading3Quarters className="icon-green animate-spin" />
                    </div>
                  ) : (
                    <div
                      className="icon-container-green h-min group "
                      onClick={handleSendEmail}
                    >
                      <HiOutlinePaperAirplane className="icon-green" />
                    </div>
                  )}
                </div>
              </div>
            </ModalXL>
            <Modal open={open} handleClose={handleClose}>
              <div className="grid grid-cols-2 gap-2">
                <div className="col-span-2 text-2xl text-center">Pagamento</div>
                <div className="col-span-2 ">
                  <StatoFatturazione
                    onChange={(val) => {
                      setSinistroSelected({
                        ...sinistroSelected,
                        stato: val,
                      });
                      setPagamentoMassivo({
                        ...pagamentoMassivo,
                        stato: val,
                      });
                    }}
                    value={
                      massive
                        ? pagamentoMassivo?.stato
                        : sinistroSelected?.stato
                    }
                    label={"Stato"}
                  />
                </div>
                {massive ? (
                  <div className="col-span-4 md:col-span-2 ">
                    <Input
                      label={"Codice Fattura"}
                      type="text"
                      name="codice_fattura"
                      onChange={(e) => {
                        setPagamentoMassivo({
                          ...pagamentoMassivo,
                          codice_fattura: e.target.value,
                        });
                      }}
                      value={pagamentoMassivo?.codice_fattura}
                      icon={<HiOutlineCurrencyEuro className="text-2xl" />}
                    />
                  </div>
                ) : (
                  <>
                    <div className="col-span-4 md:col-span-2 ">
                      <Input
                        label={"Codice Fattura"}
                        type="text"
                        name="codice_fattura"
                        onChange={(e) => {
                          setSinistroSelected({
                            ...sinistroSelected,
                            codice_fattura: e.target.value,
                          });
                        }}
                        value={sinistroSelected?.codice_fattura}
                        icon={<HiOutlineCurrencyEuro className="text-2xl" />}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-2 ">
                      <Input
                        label={"Dettaglio Pagamento"}
                        type="text"
                        name="dettaglio_pagamento"
                        onChange={(e) => {
                          setSinistroSelected({
                            ...sinistroSelected,
                            dettaglio_pagamento: e.target.value,
                          });
                        }}
                        value={sinistroSelected?.dettaglio_pagamento}
                        icon={<HiOutlineClipboard className="text-2xl" />}
                      />
                    </div>
                  </>
                )}
                {/* <div className="col-span-4 md:col-span-2 ">
                  <Input
                    label={"Codice Fattura"}
                    type="text"
                    name="codice_fattura"
                    onChange={(e) => {
                      setPagamentoMassivo({
                        ...pagamentoMassivo,
                        codice_fattura: e.target.value,
                      });
                    }}
                    value={pagamentoMassivo?.codice_fattura}
                    icon={<HiOutlineCurrencyEuro className="text-2xl" />}
                  />
                </div> */}
                <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
                  <div
                    className="icon-container-green h-min group "
                    onClick={massive ? massiveUpdate : handleSave}
                  >
                    <HiOutlineCheck className="icon-green" />
                  </div>
                </div>
              </div>
            </Modal>
          </>
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const DettaglioPagamento = () => {
  const location = useLocation();

  const [amministratore, setAmministratore] = useContext(Amministratore);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedSinistri, setSelectedSinistri] = useState([]);
  const [sinistri, setSinistri] = useState([]);
  const [sinistriBk, setSinistriBk] = useState([]);
  const [open, setOpen] = useState(false);
  const [massive, setMassive] = useState(false);
  const [pagamentoMassivo, setPagamentoMassivo] = useState({
    stato: 0,
    codice_fattura: "",
  });
  const [sinistroSelected, setSinistroSelected] = useState(null);

  const [openEmail, setOpenEmail] = useState(false);
  const [sending, setSending] = useState(false);
  const [filtro, setFiltro] = useState({ pagate: false, nonPagate: true });
  const [mail, setMail] = useState({
    mittente: "",
    alias: "",
    password: "",
    destinatari: "",
    copia: "",
    copiaNascosta: "",
    oggetto: "",
    testo: "",
    firma: "",
  });

  const [table, setTable] = useState([]);

  const handleChangeMail = (e) => {
    setMail({ ...mail, [e.target.name]: e.target.value });
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <div className="flex">
            <GridToolbarQuickFilter placeholder="Cerca..." />
            <div className="flex items-center py-2 px-3 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name={"pagate"}
                className="h-4 w-4  placeholder:text-black"
                checked={filtro?.pagate}
                onChange={handleCheckbox}
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Pagate
              </span>
            </div>
            <div className="flex items-center py-2 px-3 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name={"nonPagate"}
                className="h-4 w-4  placeholder:text-black"
                checked={filtro?.nonPagate}
                onChange={handleCheckbox}
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Non Pagate
              </span>
            </div>
            {/* <Checkbox
              label={"Pagate"}
              nome={"pagate"}
              value={filtro?.pagate}
              onChange={handleCheckbox}
            />
            <Checkbox
              label={"Pagate"}
              nome={"pagate"}
              value={filtro?.pagate}
              onChange={handleCheckbox}
            /> */}
          </div>

          {selectedIds?.length > 0 && (
            <div className="flex gap-2">
              <div className="icon-container-blue group" onClick={checkEmail}>
                <HiOutlineMail className="icon-blue" />
              </div>
              <div
                className="icon-container-blue group"
                onClick={() => {
                  setMassive(true);
                  setOpen(true);
                }}
              >
                <HiOutlineCurrencyEuro className="icon-blue" />
              </div>
            </div>
          )}
        </div>
      </GridToolbarContainer>
    );
  }

  const massiveUpdate = async () => {
    try {
      let response = await api.put("imprese/rel", {
        ids: selectedIds,
        pagamento: pagamentoMassivo,
      });
      toast.success(`Pagamenti aggiornati ${response.data.affectedRows}!`);
      handleClose();
      loadData();
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedIds([]);
    setPagamentoMassivo({
      stato: 0,
      codice_fattura: "",
    });
    setSinistroSelected(null);
    setFiltro({ pagate: false, nonPagate: true });
  };
  const checkEmail = async () => {
    //DATI BASE
    let responseTestoEmail = await api.get("tipologie_mail/" + "Pagamento");
    let responseAmministratori = await api.get("amministratori");
    let amm = responseAmministratori.data.data?.filter(
      (el) => el.id === amministratore
    )[0];
    let responseUtenti = await api.get(
      "utenti/" + sessionStorage.getItem("Username")
    );

    //DATI LAVORI IMPRESE
    let tableData = selectedSinistri.map((lavoro) => {
      let imp = "";
      imp =
        location.state?.pagamento?.nome +
        "<br/>" +
        (lavoro?.n_fattura ? "FATT " + lavoro?.n_fattura + " " : "") +
        (lavoro?.fattura ? lavoro?.fattura + "€" : "");
      let pers = lavoro?.persone ? lavoro?.persone?.split(",") : "";
      if (pers?.length >= 3) {
        pers = pers?.slice(0, 3);
      }
      pers =
        (lavoro?.danno ? lavoro?.danno + ", " : "") +
        (pers && pers?.join(", "));
      let row = {
        riferimento:
          lavoro?.riferimento +
          "/" +
          (lavoro?.data && moment(lavoro?.data).format("YY")),
        condominio: lavoro?.condominio,
        impresa: imp,
        persone: pers,
      };

      return row;
    });
    setTable(tableData);

    setMail({
      ...mail,
      mittente: amm?.email ? amm.email : "",
      alias: amm?.alias_check === 1 ? amm?.alias : "",
      password: amm?.password_email ? amm.password_email : "",
      destinatari: location.state.pagamento?.email,
      copiaNascosta: responseUtenti.data?.data[0]?.copia_nascosta,
      firma: amm.firma ? amm.firma : "",
      oggetto: "Fatturazione",
      testo: responseTestoEmail.data.data[0]?.testo,
    });
    setOpenEmail(true);
  };

  const handleInputChange = (e, rowIndex, field) => {
    const updatedTable = [...table];
    updatedTable[rowIndex][field] = e.target.value;
    setTable(updatedTable);
  };

  const handleSave = async () => {
    try {
      await api.put("imprese/rel", {
        impresa: sinistroSelected,
      });
      toast.success("Modifica completata!");
      handleClose();
      loadData();
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };

  const handleSendEmail = async () => {
    if (mail.destinatari) {
      setSending(true);
      try {
        await api.post("email/pagamenti/", {
          mittente: mail.mittente,
          alias: mail.alias,
          password: mail.password,
          destinatari: mail.destinatari,
          copia: mail?.copia,
          copiaNascosta: mail?.copiaNascosta,
          oggetto: mail.oggetto,
          testo: mail?.testo,
          table: table,
          firma: mail.firma,
          allegati: mail?.allegati ? [mail?.allegati] : "",
          utente: sessionStorage.getItem("Username"),
          tipologia: "Pagamento",
        });
        toast.success("Email inviata!");
      } catch (error) {
        toast.error("Errore durante l'invio!");
      }
      setSending(false);
    } else {
      toast.error("Campo Destinatari obbligatoro!");
    }
  };
  const handleCheckbox = (e) => {
    let filterUpdated = {
      ...filtro,
      [e.target.name]: e.target.checked,
    };
    setFiltro({ ...filterUpdated });
    console.log({
      ...filtro,
      [e.target.name]: e.target.checked,
    });
    let allImprese = sinistriBk;
    if (!filterUpdated.pagate) {
      allImprese = allImprese.filter((el) => !(el.stato === 1));
    }
    if (!filterUpdated.nonPagate) {
      allImprese = allImprese.filter((el) => el.stato === 1);
    }
    setSinistri(allImprese);
  };
  const loadData = async () => {
    const urlParams = new URLSearchParams();
    urlParams.append("da", moment(location.state?.da).format("YYYY-MM-DD"));
    urlParams.append("a", moment(location.state?.a).format("YYYY-MM-DD"));
    urlParams.append("id", location.state?.pagamento.id);
    urlParams.append("pagante", 1);
    let responseImprese;
    if (amministratore !== 0) {
      urlParams.append("amministratore", amministratore);
      responseImprese = await api.get("pagamenti/sinistro/", {
        params: urlParams,
      });
    } else {
      urlParams.append("all", true);
      responseImprese = await api.get("pagamenti/sinistro/", {
        params: urlParams,
      });
    }
    let allImprese = responseImprese.data.data;
    if (!filtro.pagate) {
      allImprese = allImprese.filter((el) => !(el.stato === 1));
    }
    if (!filtro.nonPagate) {
      allImprese = allImprese.filter((el) => el.stato === 1);
    }
    setSinistri(allImprese);
    console.log(allImprese);
    setSinistriBk(responseImprese.data.data);
  };

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, []);

  return (
    <>
      <div className="page-container ">
        <div className="w-full text-center text-lg md:text-2xl ">
          {location.state?.pagamento?.nome}
        </div>
        <div className="h-full">
          <DataGridPro
            ignoreDiacritics
            rows={sinistri}
            columns={columnsSinistri}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbar }}
            onRowClick={(row) => {
              setSinistroSelected({ ...row.row });
              setOpen(true);
              setMassive(false);
            }}
            disableRowSelectionOnClick
            checkboxSelection
            rowSelectionModel={selectedIds}
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              setSelectedIds(ids);
              const selectedRowData = sinistri.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectedSinistri(selectedRowData);
            }}
          />
        </div>
        <Modal open={open} handleClose={handleClose}>
          <div className="grid grid-cols-2 gap-2">
            <div className="col-span-2 text-2xl text-center">Pagamento</div>
            <div className="col-span-2 ">
              <StatoFatturazione
                onChange={(val) => {
                  setSinistroSelected({ ...sinistroSelected, stato: val });
                  setPagamentoMassivo({ ...pagamentoMassivo, stato: val });
                }}
                value={
                  massive ? pagamentoMassivo?.stato : sinistroSelected?.stato
                }
                label={"Stato"}
              />
            </div>
            {massive ? (
              <div className="col-span-4 md:col-span-2 ">
                <Input
                  label={"Codice Fattura"}
                  type="text"
                  name="codice_fattura"
                  onChange={(e) => {
                    setPagamentoMassivo({
                      ...pagamentoMassivo,
                      codice_fattura: e.target.value,
                    });
                  }}
                  value={pagamentoMassivo?.codice_fattura}
                  icon={<HiOutlineCurrencyEuro className="text-2xl" />}
                />
              </div>
            ) : (
              <>
                <div className="col-span-4 md:col-span-2 ">
                  <Input
                    label={"Codice Fattura"}
                    type="text"
                    name="codice_fattura"
                    onChange={(e) => {
                      setSinistroSelected({
                        ...sinistroSelected,
                        codice_fattura: e.target.value,
                      });
                    }}
                    value={sinistroSelected?.codice_fattura}
                    icon={<HiOutlineCurrencyEuro className="text-2xl" />}
                  />
                </div>
                <div className="col-span-4 md:col-span-2 ">
                  <Input
                    label={"Dettaglio Pagamento"}
                    type="text"
                    name="dettaglio_pagamento"
                    onChange={(e) => {
                      setSinistroSelected({
                        ...sinistroSelected,
                        dettaglio_pagamento: e.target.value,
                      });
                    }}
                    value={sinistroSelected?.dettaglio_pagamento}
                    icon={<HiOutlineClipboard className="text-2xl" />}
                  />
                </div>
              </>
            )}
            <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
              <div
                className="icon-container-green h-min group "
                onClick={massive ? massiveUpdate : handleSave}
              >
                <HiOutlineCheck className="icon-green" />
              </div>
            </div>
          </div>
        </Modal>
        <ModalXL open={openEmail} handleClose={() => setOpenEmail(false)}>
          <div className="grid grid-cols-2 gap-2 h-full px-2">
            <div className="col-span-2 text-2xl text-center">
              Invio Pagamenti
            </div>
            <div className="col-span-2">
              <Input
                label={"Mittente"}
                type="text"
                name="mittente"
                onChange={handleChangeMail}
                value={mail?.mittente}
                icon={<HiOutlineUser className="text-2xl" />}
              />
            </div>
            {mail?.alias && (
              <div className="col-span-2">
                <Input
                  label={"Alias"}
                  type="text"
                  name="alias"
                  onChange={handleChangeMail}
                  value={mail?.alias}
                  icon={<HiOutlineUser className="text-2xl" />}
                />
              </div>
            )}
            <div className="col-span-2">
              <Input
                label={"Destinatari"}
                type="text"
                name="destinatari"
                onChange={handleChangeMail}
                value={mail?.destinatari}
                icon={<HiOutlineUser className="text-2xl" />}
              />
            </div>
            <div className="col-span-2">
              <Input
                label={"In Copia"}
                type="text"
                name="copia"
                onChange={handleChangeMail}
                value={mail?.copia}
                icon={<HiOutlineUsers className="text-2xl" />}
              />
            </div>
            <div className="col-span-2">
              <Input
                label={"In Copia Nascosta"}
                type="text"
                name="copiaNascosta"
                onChange={handleChangeMail}
                value={mail?.copiaNascosta}
                icon={<HiOutlineUsers className="text-2xl" />}
              />
            </div>
            <div className="col-span-2">
              <Input
                label={"Oggetto"}
                type="text"
                name="oggetto"
                onChange={handleChangeMail}
                value={mail?.oggetto}
                icon={<HiOutlineUsers className="text-2xl" />}
              />
            </div>

            <div className="col-span-2 ">
              <span className="text-lg font-normal">Testo</span>
              <div className="relative">
                <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                  <HiOutlineDocumentText className="text-2xl" />
                </span>

                <textarea
                  rows={2}
                  type="text"
                  name="testo"
                  className="input flex items-center"
                  placeholder="Inserisci Testo"
                  onChange={handleChangeMail}
                  value={mail?.testo}
                />
              </div>
            </div>
            <div className="col-span-2">
              <span className="text-lg font-normal">Tabella</span>
              <table className="w-full ">
                <thead>
                  <tr>
                    <th className="border border-black">Riferimento</th>
                    <th className="border border-black">Condominio</th>
                    <th className="border border-black">Impresa</th>
                    <th className="border border-black">Persone</th>
                  </tr>
                </thead>
                <tbody>
                  {table?.map((t, rowIndex) => (
                    <tr key={rowIndex} className="border border-black h-full">
                      <td className="border border-black">
                        <input
                          type="text"
                          className="text-center w-full"
                          value={t.riferimento}
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "riferimento")
                          }
                        />
                      </td>
                      <td className="border border-black">
                        <input
                          className="text-center w-full"
                          type="text"
                          value={t.condominio}
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "condominio")
                          }
                        />
                      </td>
                      <td className="border border-black">
                        <textarea
                          type="text"
                          className="text-center w-full"
                          value={t.impresa.replace("<br/>", " ")}
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "impresa")
                          }
                        />
                      </td>
                      <td className="border border-black">
                        <textarea
                          type="text"
                          className="text-center w-full"
                          value={t.persone}
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "persone")
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <table className="w-full">
                <tr className="border border-black">
                  <th>RIFERIMENTO</th>
                  <th>CONDOMINIO</th>
                  <th>FATTURA</th>
                  <th>DANNEGGIATI</th>
                </tr>
                {table?.map((t) => (
                  <tr className="border border-black">
                    <td className="text-center">{t.riferimento}</td>
                    <td className="text-center">{t.condominio}</td>
                    <td className="text-center">
                      {t.impresa?.replace("<br/>", " ")}
                    </td>
                    <td className="text-center">{t.persone}</td>
                  </tr>
                ))}
              </table> */}
            </div>
            {/* <div className="col-span-2 ">
              <span className="text-lg font-normal">Firma</span>
              <div className="relative">
                <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                  <PiSignature className="text-2xl" />
                </span>

                <textarea
                  rows={5}
                  type="text"
                  name="testo"
                  className="input flex items-center"
                  value={mail?.firma}
                />
              </div>
            </div> */}
            <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
              {sending ? (
                <div
                  className="icon-container-green group "
                  onClick={() => toast.warning("Invio in corso!")}
                >
                  <AiOutlineLoading3Quarters className="icon-green animate-spin" />
                </div>
              ) : (
                <div
                  className="icon-container-green h-min group "
                  onClick={handleSendEmail}
                >
                  <HiOutlinePaperAirplane className="icon-green" />
                </div>
              )}
            </div>
          </div>
        </ModalXL>
      </div>
    </>
  );
};
