import { useEffect, useState } from "react";
import {
  HiOutlineCheck,
  HiOutlineListBullet,
  HiOutlinePlus,
  HiOutlineTrash,
} from "react-icons/hi2";
import { MdOutlineLocalOffer } from "react-icons/md";
import { TbPigMoney } from "react-icons/tb";
import { FaCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import { Input } from "./Input";
import { useLocation } from "react-router-dom";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Modal } from "./Modal";
import api from "./api/api";
import { ModalDelete } from "./ModalDelete";

const columnsPersone = [
  {
    field: "cognome",
    headerName: "Cognome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "nome",
    headerName: "Nome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "liquidazione",
    headerName: "Liquidazione",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueFormatter: (value) => value && value + "€",
  },
];

const columnsOfferte = [
  {
    field: "offerta",
    headerName: "Offerta",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "n_offerta",
    headerName: "N°",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

const formatNumber = (num) => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "˙");
};

export const ComponentFattura = ({
  dati,
  sinistro,
  operazione,
  personeInteressate,
  reloadLiquidazioni,
}) => {
  const location = useLocation();
  const [fattura, setFattura] = useState(null);

  const [openDeleteOfferta, setOpenDeleteOfferta] = useState(false);
  const [offertaSelected, setOfferteSelected] = useState(false);

  const [columnsFranchigie, setColumnsFranchigie] = useState([]);
  const [franchigie, setFranchigie] = useState([]);
  const [offerte, setOfferte] = useState([]);
  const [personeInteressateList, setPersoneInteressateList] = useState([]);

  const [formattedNumber, setFormattedNumber] = useState("");

  const [openPersone, setOpenPersone] = useState(false);
  const [personaInteressata, setPersonaInteressata] = useState({
    id_persona_interessata: "",
    liquidazione: 0,
  });

  const handleSavePersona = async () => {
    try {
      await api.put("persone_interessate/rel", {
        persona_interessata: personaInteressata,
      });
      toast.success("Salvataggio completato!");
      handleClosePersone();
    } catch (error) {
      toast.error("Errore durante il salvataggio. Riprova!");
    }
  };

  const handleChange = (e) => {
    setFattura({ ...fattura, [e.target.name]: e.target.value });
  };

  const handleNumberChange = (newValue, fatt) => {
    const formattedValue = formatNumber(newValue);
    if (fatt) {
      setFattura({ ...fattura, offerta: newValue });
    } else {
      setFattura({ ...fattura, offerta: newValue });
    }
    setFormattedNumber(formattedValue);
  };

  const handleSave = async () => {
    try {
      await api.post("offerte/", {
        offerta: { ...fattura, id_sinistro: location.state?.sinistro.id },
      });
      toast.success("Salvataggio completato!");
      reloadLiquidazioni();
    } catch (error) {
      toast.error("Errore durante il salvataggio. Riprova!");
    }
  };

  const handleDeleteOfferta = async () => {
    try {
      await api.delete("offerte/"+ offertaSelected?.id);
      toast.success("Salvataggio completato!");
      reloadLiquidazioni();
      handleLoadData();
    } catch (error) {
      toast.error("Errore durante il salvataggio. Riprova!");
    }
  };

  const openDeleteModal = (offerta) => {
    setOpenDeleteOfferta(true);
    setOfferteSelected(offerta);
  };

  const handleClosePersone = async () => {
    let responseRelPersone = await api.get(
      "persone_interessate/sinistro/" + location.state?.sinistro.id
    );
    setPersoneInteressateList(responseRelPersone.data.data);
    setOpenPersone(false);
  };
  const handleDelete = () => {
    toast.success("Fattura eliminata!");
  };
  const handleLoadData = () => {
    if (operazione === "new") {
      setFattura({
        n_offerta: 1,
        respinta: 0,
        offerta: 0,
      });
    } else {
      //setto come valori della fattura l'ultima fattura aggiunta
      setFattura(dati.filter((el) => el.n_offerta === dati.length)[0]);
      handleNumberChange(
        dati.filter((el) => el.n_offerta === dati.length)[0]?.offerta,
        dati.filter((el) => el.n_offerta === dati.length)[0]
      );
      setOfferte(dati);
    }
  }
  useEffect(() => {
    (async () => {
handleLoadData()
      setPersoneInteressateList(personeInteressate);
      let responseFranchigie = await api.get(
        "polizze/franchigie/sinistro/" + location.state?.sinistro?.id
      );

      let franchigia = responseFranchigie.data.data.filter(
        (el) =>
          el.id_garanzia === location.state?.sinistro?.id_tipologia_sinistro
      );
      setFranchigie(franchigia);

      if (franchigia[0]?.id_garanzia === 1) {
        setColumnsFranchigie([
          {
            field: "garanzia",
            headerName: "Garanzia",
            width: 150,
            editable: false,
            align: "center",
            headerAlign: "center",
          },
          {
            field: "valore",
            headerName: "Valore RG",
            width: 100,
            editable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: (value) => value && value + "€",
          },
          {
            field: "doppio_valore",
            headerName: "Valore AC",
            width: 100,
            editable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: (value) => value && value + "€",
          },
          {
            field: "franchigia_scoperto",
            headerName: "Scoperto",
            width: 100,
            editable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
              return (
                <div className="w-full h-full flex items-center justify-center">
                  <FaCircle
                    className={`text-xl`}
                    color={
                      params.row?.franchigia_scoperto === 1 ? "green" : "red"
                    }
                  />
                </div>
              );
            },
          },
          {
            field: "percentuale",
            headerName: "Percentuale",
            width: 100,
            editable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: (value) => value && value + "%",
          },
          {
            field: "minimo",
            headerName: "Minimo",
            width: 100,
            editable: false,
            align: "center",
            headerAlign: "center",
          },
        ]);
      } else {
        setColumnsFranchigie([
          {
            field: "garanzia",
            headerName: "Garanzia",
            width: 150,
            editable: false,
            align: "center",
            headerAlign: "center",
          },
          {
            field: "valore",
            headerName: "Valore",
            width: 100,
            editable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: (value) => value && value + "€",
          },
          {
            field: "franchigia_scoperto",
            headerName: "Scoperto",
            width: 100,
            editable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
              return (
                <div className="w-full h-full flex items-center justify-center">
                  <FaCircle
                    className={`text-xl`}
                    color={
                      params.row?.franchigia_scoperto === 1 ? "green" : "red"
                    }
                  />
                </div>
              );
            },
          },
          {
            field: "percentuale",
            headerName: "Percentuale",
            width: 100,
            editable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: (value) => value && value + "%",
          },
          {
            field: "minimo",
            headerName: "Minimo",
            width: 100,
            editable: false,
            align: "center",
            headerAlign: "center",
          },
        ]);
      }
    })();
  }, [dati, personeInteressate]);
  return (
    <>
      <div className="grid grid-cols-4 gap-4 px-2  h-[calc(100vh_-_320px)]  lg:h-[calc(100vh_-_250px)] overflow-y-auto">
        {offerte.length > 0 ? (
          <>
            {offerte?.map((el) => {
              if (el.n_offerta === offerte.length) {
                return (
                  <div
                    key={el.id}
                    className="col-span-4 grid grid-cols-4 gap-4"
                  >
                    <div className="col-span-4 md:col-span-2 gap-2 flex items-end">
                      <div className="w-full">
                        <NumberInput
                          value={formattedNumber}
                          onChange={handleNumberChange}
                        />
                        {/* <Input
                          label={"Indennizzo Netto"}
                          type="text"
                          name="offerta"
                          onChange={handleChange}
                          value={fattura?.offerta}
                          icon={<TbPigMoney className="text-2xl" />}
                        /> */}
                      </div>
                      <div
                        className="icon-container-green group h-min "
                        onClick={() => {
                          //INCREMENTERO' I NUMERI IN BASE ALL'ULTIMO OGGETTO
                          let newFattura = {
                            n_offerta:
                              Math.max(...offerte.map((o) => o.n_offerta)) + 1,
                            respinta: 0,
                            offerta: 0,
                          };
                          setFattura(newFattura);
                          setOfferte([newFattura, ...offerte]);
                        }}
                      >
                        <HiOutlinePlus className="icon-green" />
                      </div>
                    </div>
                    <div className="col-span-4 flex gap-4 items-center justify-end flex-1 mt-6">
                      <div
                        className="icon-container-green h-min group "
                        onClick={handleSave}
                      >
                        <HiOutlineCheck className="icon-green" />
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={el.id}
                    className="col-span-4 grid grid-cols-4 gap-4"
                  >
                    {/* {el.n_offerta === 2 && (
                      <>
                        <div className="col-span-4 h-1 bg-sinistri-lightgreen rounded-full"></div>
                        <div className="col-span-4 text-center text-2xl">
                          STORICO OFFERTE
                        </div>
                      </>
                    )} */}
                    <div className="col-span-4 md:col-span-2 gap-2 flex items-end">
                      <div className="w-full">
                        <Input
                          label={"Offerta " + el.n_offerta}
                          type="text"
                          name="offerta"
                          disabled={true}
                          //onChange={handleChange}
                          value={formatNumber(el?.offerta)}
                          icon={<TbPigMoney className="text-2xl" />}
                        />
                      </div>
                      <div
                        className="icon-container-red group h-min "
                        onClick={() => openDeleteModal(el)}
                      >
                        <HiOutlineTrash className="icon-red" />
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </>
        ) : (
          <>
            <div className="col-span-4 md:col-span-2  flex items-end">
              <div className="w-full">
                <NumberInput
                  value={formattedNumber}
                  onChange={handleNumberChange}
                />
                {/* <Input
                  label={"Indennizzo Netto"}
                  type="text"
                  name="offerta"
                  onChange={handleChange}
                  value={fattura?.offerta}
                  icon={<TbPigMoney className="text-2xl" />}
                /> */}
              </div>
            </div>
            <div className="col-span-4 flex gap-4 items-center justify-end flex-1 mt-6">
              {operazione === "edit" && (
                <div
                  className="icon-container-red h-min group"
                  onClick={handleDelete}
                >
                  <HiOutlineTrash className="icon-red" />
                </div>
              )}
              <div
                className="icon-container-green h-min group "
                onClick={handleSave}
              >
                <HiOutlineCheck className="icon-green" />
              </div>
            </div>
          </>
        )}

        {/* <div className="col-span-4 h-60">
          <DataGridPro
            rows={offerte}
            columns={columnsOfferte}
            getRowId={(row) => row.id}
            disableRowSelectionOnClick
          />
        </div> */}
        {/* <div className="col-span-4 md:col-span-2 ">
          <Input
            label={"Prima Offerta"}
            type="text"
            name="prima_offerta"
            onChange={handleChange}
            value={fattura?.prima_offerta}
            icon={<MdOutlineLocalOffer className="text-2xl" />}
          />
        </div> */}

        <div className="col-span-4 h-1 bg-sinistri-lightgreen rounded-full"></div>
        <div className="col-span-4 md:col-span-2 grid grid-cols-4 gap-4 ">
          <div className="col-span-4 text-center text-2xl">FRANCHIGIE</div>
          <div className="col-span-4 h-80">
            <DataGridPro
              rows={franchigie}
              columns={columnsFranchigie}
              getRowId={(row) => row.id}
              //slots={{ toolbar: CustomToolbarFranchigie }}
              /* onRowClick={(row) => {
              setFranchigia(row.row);
              setOperation("edit");
                setOpenFranchigia(true);
              }} */
              disableRowSelectionOnClick
            />
          </div>
        </div>
        <div className="col-span-4 md:col-span-2 grid grid-cols-4 gap-4 ">
          <div className="col-span-4 text-center text-2xl">
            PERSONE INTERESSATE
          </div>
          <div className="col-span-4 h-80">
            <DataGridPro
              rows={personeInteressateList}
              columns={columnsPersone}
              getRowId={(row) => row.id}
              //slots={{ toolbar: ToolbarPersoneInteressate }}
              onRowClick={(row) => {
                setPersonaInteressata({ ...row.row });
                setOpenPersone(true);
              }}
              disableRowSelectionOnClick
            />
          </div>
        </div>
      </div>
      <Modal open={openPersone} handleClose={handleClosePersone}>
        <div className="grid grid-cols-2 gap-2">
          <div className="col-span-2 text-2xl text-center">Persona</div>
          <div className="col-span-2 md:col-span-1">
            <Input
              label={"Cognome"}
              type="text"
              name="cognome"
              disabled={true}
              //onChange={handleChange}
              value={personaInteressata?.cognome}
              icon={<HiOutlineListBullet className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              disabled={true}
              //onChange={handleChange}
              value={personaInteressata?.nome}
              icon={<HiOutlineListBullet className="text-2xl" />}
            />
            {/* <Autocomplete
              disabled={true}
              suggestions={personeInteressateTot}
              fields={["nome", "cognome", "telefono"]}
              keyField="id"
              value={personaInteressata?.id_persona_interessata}
              id="nome"
              label="Persona"
              name="nome"
              icon={<HiOutlineUserGroup className="text-2xl" />}
            /> */}
          </div>
          <div className="col-span-2">
            <Input
              label={"Liquidazione"}
              type="text"
              name="liquidazione"
              onChange={(e) =>
                setPersonaInteressata({
                  ...personaInteressata,
                  liquidazione: e.target.value,
                })
              }
              value={personaInteressata?.liquidazione}
              icon={<MdOutlineLocalOffer className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={handleSavePersona}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </Modal>
      <ModalDelete
        open={openDeleteOfferta}
        handleClose={() => setOpenDeleteOfferta(false)}
        description={"Offerta da: " + formatNumber(offertaSelected?.offerta) + "€"}
        handleDelete={handleDeleteOfferta}
      />
    </>
  );
};

function NumberInput({ value, onChange, disabled }) {
  const handleChange = (e) => {
    const rawValue = e.target.value;
    // Remove commas from the input
    const sanitizedValue = rawValue.replace(/˙/g, "");
    // Only allow numeric characters
    if (!isNaN(sanitizedValue)) {
      // Update the input field with unformatted value
      onChange(sanitizedValue);
    }
  };

  return (
    <Input
      label={"Indennizzo Netto"}
      type="text"
      name="offerta"
      disabled={disabled}
      onChange={handleChange}
      value={value}
      icon={<TbPigMoney className="text-2xl" />}
    />
  );
}
