import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@mui/material";
export const DataTimeInput = ({ label, onChange, value, disabled }) => {
  return (
    <>
      <>
        <span className="text-base font-normal">{label}</span>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            disabled
            ampmInClock={false}
            value={value}
            onChange={onChange}
            className="input-data"
          />
        </LocalizationProvider>
      </>
    </>
  );
};
