import { useCallback, useContext, useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Skeleton, Tooltip } from "@mui/material";
import {
  HiMiniArrowPath,
  HiOutlineBriefcase,
  HiOutlineBuildingStorefront,
  HiOutlineCheck,
  HiOutlineClock,
  HiOutlineIdentification,
  HiOutlinePlus,
  HiOutlineTrash,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Input } from "../components/Input";
import { Autocomplete } from "../components/Autocomplete";
import { Amministratore } from "../utility/useAmministraotre";
import { FaCircle } from "react-icons/fa";
import api from "../components/api/api";
import { toast } from "react-toastify";
import { ModalDelete } from "../components/ModalDelete";
import { NuovaCompagnia } from "./Compagnie";
import { NuovaAgenzia } from "./Agenzie";
import { Modal } from "../components/Modal";
import { DataInput } from "../components/DataInput";
import { ModalXL } from "../components/ModalXL";

const columnsPolizze = [
  {
    field: "*",
    headerName: "Stato",
    width: 80,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      let color = "green";
      let tooltip = "";
      if (
        moment(new Date()).add(1, "M").format("YYYY-MM-DD") <
        moment(params.row.scadenza).format("YYYY-MM-DD")
      ) {
        color = "green";
        tooltip = "Aggiornata";
      } else if (
        //se scade entro 1 mese-> ARANCIONE
        moment(new Date()).add(1, "M").format("YYYY-MM-DD") >
          moment(params.row.scadenza).format("YYYY-MM-DD") &&
        moment(params.row.scadenza).format("YYYY-MM-DD") >=
          moment(new Date()).format("YYYY-MM-DD")
      ) {
        color = "orange";
        tooltip = "In Scadenza";
        //se scaduto-> ROSSO
      } else {
        color = "red";
        tooltip = "Scaduta";
      }
      return (
        <Tooltip title={tooltip}>
          <div className="w-full h-full flex items-center justify-center">
            <FaCircle className={`text-3xl`} color={color} />
          </div>
        </Tooltip>
      );
    },
  },
  {
    field: "data",
    headerName: "Data",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueFormatter: (value) => value && moment(value).format("YYYY-MM-DD"),
  },
  {
    field: "scadenza",
    headerName: "Scadenza",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueFormatter: (value) => value && moment(value).format("YYYY-MM-DD"),
  },
  {
    field: "n_polizza",
    headerName: "N° Polizza",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  /*   {
    field: "franchigie",
    headerName: "Franchigie",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  }, */
];

const columnsPersone = [
  {
    field: "cognome",
    headerName: "Cognome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "nome",
    headerName: "Nome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "telefono",
    headerName: "Telefono",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "piano",
    headerName: "Piano",
    width: 80,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

const columns = [
  {
    field: "nome",
    headerName: "Nome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "*",
    headerName: "Polizza",
    width: 80,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      let color = "green";
      let tooltip = "";
      if (!params.row.scadenza) {
        color = "gray";
        tooltip = "Non presente";
      } else if (
        moment(new Date()).add(1, "M").format("YYYY-MM-DD") <
        moment(params.row.scadenza).format("YYYY-MM-DD")
      ) {
        color = "green";
        tooltip = "Aggiornata";
      } else if (
        //se scade entro 1 mese-> ARANCIONE
        moment(new Date()).add(1, "M").format("YYYY-MM-DD") >
          moment(params.row.scadenza).format("YYYY-MM-DD") &&
        moment(params.row.scadenza).format("YYYY-MM-DD") >=
          moment(new Date()).format("YYYY-MM-DD")
      ) {
        color = "orange";
        tooltip =
          "In Scadenza " + moment(params.row.scadenza).format("YYYY-MM-DD");
        //se scaduto-> ROSSO
      } else {
        color = "red";
        tooltip = "Scaduta";
      }
      return (
        <Tooltip title={tooltip}>
          <div className="w-full h-full flex items-center justify-center">
            <FaCircle className={`text-3xl`} color={color} />
          </div>
        </Tooltip>
      );
    },
  },
  {
    field: "compagnia",
    headerName: "Compagnia",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "agenzia",
    headerName: "Agenzia",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "amministratore",
    headerName: "Amministratore",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

const findNewObject = (arr1, arr2) => {
  return arr1.filter((obj1) => !arr2.some((obj2) => obj1.id === obj2.id));
};

const columnsStorico = [
  {
    field: "compagnia",
    headerName: "Compagnia",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "agenzia",
    headerName: "Agenzia",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "data_inizio",
    headerName: "Inizio",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueFormatter: (value) => value && moment(value).format("YYYY-MM-DD"),
  },
  {
    field: "data_termine",
    headerName: "Termine",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueFormatter: (value) => value && moment(value).format("YYYY-MM-DD"),
  },
];

export const Condominii = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [amministratore, setAmministratore] = useContext(Amministratore);
  const [condominii, setCondominii] = useState([]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => navigate("/Nuovo-Condominio")}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    (async () => {
      setLoading(true);
      let responseCondominii;
      if (amministratore !== 0) {
        responseCondominii = await api.get("condominii/" + amministratore);
      } else {
        responseCondominii = await api.get("condominii");
      }
      setCondominii(responseCondominii.data.data);
      setLoading(false);
    })();
  }, [amministratore]);

  return (
    <div className="page-container ">
      <div className="w-full container-box h-full">
        <div className=""></div>
        {!loading ? (
          <DataGridPro
            ignoreDiacritics
            rows={condominii}
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbar }}
            onRowClick={(row) => {
              navigate("/Dettaglio-Condominio", {
                state: { condominio: row.row },
              });
            }}
            disableRowSelectionOnClick
          />
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const NuovoCondominio = ({ external }) => {
  const navigate = useNavigate();

  const [openNewCompagnia, setOpenNewCompagnia] = useState(false);
  const [openNewAgenzia, setOpenNewAgenzia] = useState(false);

  const [amministratore, setAmministratore] = useContext(Amministratore);
  const [agenzie, setAgenzie] = useState([]);
  const [compagnie, setCompagnie] = useState([]);

  const [condominio, setCondominio] = useState({
    nome: "",
    indirizzo: "",
    intestazione_polizza: "",
    id_compagnia_assicurativa: 0,
    id_agenzia: 0,
  });
  const handleChange = (e) => {
    setCondominio({ ...condominio, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setCondominio({
      ...condominio,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const handleSave = async () => {
    if (condominio.nome === "") {
      toast.error("Campo Nome obbligatorio!");
    } /* else if(condominio.id_compagnia_assicurativa === 0){
      toast.error("Campo Compagnia obbligatorio!");
    } else if(condominio.id_agenzia === 0){
      toast.error("Campo Agenzia obbligatorio!");
    } */ else {
      let responseCondominii;
      if (amministratore !== 0) {
        responseCondominii = await api.get("condominii/" + amministratore);
      } else {
        responseCondominii = await api.get("condominii");
      }
      if (
        responseCondominii.data.data?.every(
          (cond) => cond.nome !== condominio.nome
        )
      ) {
        try {
          let response = await api.post("condominii", {
            condominio: { ...condominio, id_amministratore: amministratore },
          });
          toast.success("Salvataggio completato!");
          !external &&
            navigate("/Dettaglio-Condominio", {
              state: {
                condominio: {
                  ...condominio,
                  id: response.data?.newId,
                  id_rel_condominio_compagnia_assicurativa:
                    response.data?.newIdRel,
                },
              },
            });
        } catch (error) {
          toast.error("Errore durante il salvataggio. Riprova!");
        }
      } else {
        toast.error("Nome duplicato. Modifica!");
      }
    }
  };

  useEffect(() => {
    (async () => {
      let responseAgenzie = await api.get("agenzie");
      setAgenzie(responseAgenzie.data.data);
      let responseCompagnie = await api.get("compagnie_assicurative");
      setCompagnie(responseCompagnie.data.data);
    })();
  }, []);

  return (
    <>
      <div className={!external && "page-container "}>
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-2 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={condominio?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          {/* <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Indirizzo"}
              type="text"
              name="indirizzo"
              onChange={handleChange}
              value={condominio?.indirizzo}
              icon={<HiOutlineMapPin className="text-2xl" />}
            />
          </div> */}
          <div className="col-span-2 md:col-span-1  flex items-end gap-2">
            <Autocomplete
              suggestions={compagnie}
              fields={["nome"]}
              keyField="id"
              value={condominio?.id_compagnia_assicurativa}
              id="nome"
              label="Compagnia"
              name="nome"
              onChangeFunc={(e) => {
                setCondominio({
                  ...condominio,
                  id_compagnia_assicurativa: e,
                });
              }}
              icon={<HiOutlineBriefcase className="text-2xl" />}
            />
            <div
              className="icon-container-green group "
              onClick={() => setOpenNewCompagnia(true)}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
          <div className="col-span-2 md:col-span-1  flex items-end gap-2">
            <Autocomplete
              suggestions={agenzie}
              fields={["nome"]}
              keyField="id"
              value={condominio?.id_agenzia}
              id="nome"
              label="Agenzie"
              name="nome"
              onChangeFunc={(e) => {
                setCondominio({
                  ...condominio,
                  id_agenzia: e,
                });
              }}
              icon={<HiOutlineBuildingStorefront className="text-2xl" />}
            />
            <div
              className="icon-container-green group "
              onClick={() => setOpenNewAgenzia(true)}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openNewCompagnia}
        handleClose={async () => {
          let responseCompagnia = await api.get("compagnie_assicurative");
          setCompagnie(responseCompagnia.data.data);
          if (responseCompagnia.data.data?.length !== compagnie?.length) {
            setCondominio({
              ...condominio,
              id_compagnia_assicurativa:
                /* responseCompagnia.data.data[
                  responseCompagnia.data.data.length - 1
                ]?.id, */
                findNewObject(responseCompagnia.data.data, compagnie)[0]?.id,
            });
          }
          setOpenNewCompagnia(false);
        }}
      >
        <h2 className="text-2xl text-center mb-4">Nuova Compagnia</h2>
        <NuovaCompagnia external={true} />
      </Modal>
      <Modal
        open={openNewAgenzia}
        handleClose={async () => {
          let responseAgenzia = await api.get("agenzie");
          setAgenzie(responseAgenzia.data.data);
          if (responseAgenzia.data.data?.length !== agenzie?.length) {
            setCondominio({
              ...condominio,
              id_agenzia:
                /* responseAgenzia.data.data[responseAgenzia.data.data.length - 1]
                  ?.id, */
                findNewObject(responseAgenzia.data.data, agenzie)[0]?.id,
            });
          }
          setOpenNewAgenzia(false);
        }}
      >
        <h2 className="text-2xl text-center mb-4">Nuova Agenzia</h2>
        <NuovaAgenzia external={true} />
      </Modal>
    </>
  );
};

export const DettaglioCondominio = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [polizze, setPolizze] = useState([]);

  const [condominio, setCondominio] = useState(null);

  const [openChangeCompagnia, setOpenChangeCompagnia] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [openDeleteStorico, setOpenDeleteStorico] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const [storico, setStorico] = useState([]);
  const [storicoPolizze, setStoricoPolizze] = useState([]);
  const [openStorico, setOpenStorico] = useState(false);

  const [compagnie, setCompagnie] = useState([]);
  const [agenzie, setAgenzie] = useState([]);
  const [personeInteressate, setPersoneInteressata] = useState([]);
  const [condominii, setCondominii] = useState([]);
  const [amministratore, setAmministratore] = useContext(Amministratore);

  const [dataInizio, setDataInizio] = useState(null);

  const [relCompagniaAgenzia, setRelCompagniaAgenzia] = useState({
    id_agenzia: "",
    id_compagnia_assicurativa: "",
    data_termine: null,
    data_inizio: null,
  });

  const handleChange = (e) => {
    setCondominio({ ...condominio, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setCondominio({
      ...condominio,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const handleSaveRelCompagniaAgenzia = async () => {
    if (
      relCompagniaAgenzia.id_agenzia !== "" ||
      relCompagniaAgenzia.id_compagnia_assicurativa !== "" ||
      !relCompagniaAgenzia.data_termine ||
      !relCompagniaAgenzia.data_inizio
    ) {
      try {
        let response = await api.put("condominii/rel", {
          condominio: {
            ...condominio,
            id_condominio: location.state?.condominio?.id,
            id_agenzia: relCompagniaAgenzia.id_agenzia,
            id_compagnia_assicurativa:
              relCompagniaAgenzia.id_compagnia_assicurativa,
            data_termine: relCompagniaAgenzia.data_termine,
            data_inizio: relCompagniaAgenzia.data_inizio,
          },
        });
        toast.success("Modifica completata!");
        let responsePolizze = await api.get("polizze/" + response.data?.newId);
        setPolizze(responsePolizze.data.data);
        setCondominio({
          ...condominio,
          id_rel_condominio_compagnia_assicurativa: response.data?.newId,
          id_agenzia: relCompagniaAgenzia.id_agenzia,
          id_compagnia_assicurativa:
            relCompagniaAgenzia.id_compagnia_assicurativa,
        });
        setOpenChangeCompagnia(false);
      } catch (error) {
        toast.error("Errore durante la modifica. Riprova!");
      }
    } else {
      toast.error(
        "Dati mancanti! Seleziona sia la Compagnia che l'Agenzia e le Date"
      );
    }
  };
  const handleSave = async () => {
    let responseCondominii;
    if (amministratore !== 0) {
      responseCondominii = await api.get("condominii/" + amministratore);
    } else {
      responseCondominii = await api.get("condominii");
    }
    if (
      responseCondominii.data.data
        ?.filter((el) => el.id !== location.state?.condominio?.id)
        .every((cond) => cond.nome !== condominio.nome)
    ) {
      try {
        await api.put("condominii", {
          condominio: condominio,
        });
        toast.success("Modifica completata!");
      } catch (error) {
        toast.error("Errore durante la modifica. Riprova!");
      }
    } else {
      toast.error("Nome duplicato. Modifica!");
    }
  };

  const handleUpdate = async () => {
    try {
      await api.put("condominii/rel", {
        update: true,
        condominio: {
          ...condominio,
          data_inizio: moment(condominio.data_inizio).format("YYYY-MM-DD"),
        },
      });
      toast.success("Modifica completata!");
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };

  const handleDelete = async () => {
    try {
      await api.delete("condominii/" + condominio?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };
  const deleteStorico = async () => {
    try {
      let response = await api.delete("condominii/rel/" + selectedIds);
      toast.success(`Contratti eliminati: ${response.data?.affectedRows}!`);
      setOpenStorico(false);
      setSelectedIds([]);
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };

  const getStoricoCompagnieAgenzie = async () => {
    let responseStorico = await api.get(
      "condominii/rel/" + location.state?.condominio?.id
    );
    setStorico(responseStorico.data.data);
    setOpenStorico(true);
  };

  const getDetailPanelContent = useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );

  function CustomToolbarPolizze() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() =>
                navigate("/Nuova-Polizza", {
                  state: {
                    id_rel:
                      condominio
                        ?.id_rel_condominio_compagnia_assicurativa,
                  },
                })
              }
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          {/* <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() =>
                navigate("/Nuova-Persona-Interessata", {
                  state: { condominii: condominii, condominio: condominio?.id },
                })
              }
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div> */}
        </div>
      </GridToolbarContainer>
    );
  }
  function CustomToolbarStorico() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          {selectedIds?.length > 0 && (
            <div className="flex gap-2">
              <div
                className="icon-container-red group "
                onClick={() => setOpenDeleteStorico(true)}
              >
                <HiOutlineTrash className="icon-red" />
              </div>
            </div>
          )}
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      setCondominio(location.state?.condominio);
      console.log(location.state?.condominio);

      let responseAgenzie = await api.get("agenzie");
      setAgenzie(responseAgenzie.data.data);
      let responseCompagnie = await api.get("compagnie_assicurative");
      setCompagnie(responseCompagnie.data.data);
      let responsePersone = await api.get(
        "persone_interessate/condominio/" + location.state?.condominio?.id
      );
      setPersoneInteressata(responsePersone.data.data);
      let responseCondominii;
      if (amministratore !== 0) {
        responseCondominii = await api.get("condominii/" + amministratore);
      } else {
        responseCondominii = await api.get("condominii");
      }
      setCondominii(responseCondominii.data.data);
      let responsePolizze = await api.get(
        "polizze/" +
          location.state?.condominio?.id_rel_condominio_compagnia_assicurativa
      );
      setPolizze(responsePolizze.data.data);
    })();
  }, []);

  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-2 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={condominio?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-red h-min group"
              onClick={() => setOpenDelete(true)}
            >
              <HiOutlineTrash className="icon-red" />
            </div>
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
          {/* <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Indirizzo"}
              type="text"
              name="indirizzo"
              onChange={handleChange}
              value={condominio?.indirizzo}
              icon={<HiOutlineMapPin className="text-2xl" />}
            />
          </div> */}
          <div className="col-span-2 h-1 bg-sinistri-blue rounded-full"></div>
          <div className="col-span-2 text-center text-2xl">CONTRATTO</div>
          <div className="col-span-2 md:col-span-1 flex items-end gap-2">
            <Autocomplete
              //disabled={true}
              suggestions={compagnie}
              fields={["nome"]}
              keyField="id"
              value={condominio?.id_compagnia_assicurativa}
              id="nome"
              label="Compagnia"
              name="nome"
              onChangeFunc={(e) => {
                setCondominio({
                  ...condominio,
                  id_compagnia_assicurativa: e,
                });
              }}
              icon={<HiOutlineBriefcase className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 flex items-end gap-2">
            <Autocomplete
              //disabled={true}
              suggestions={agenzie}
              fields={["nome"]}
              keyField="id"
              value={condominio?.id_agenzia}
              id="nome"
              label="Agenzie"
              name="nome"
              onChangeFunc={(e) => {
                setCondominio({
                  ...condominio,
                  id_agenzia: e,
                });
              }}
              icon={<HiOutlineBuildingStorefront className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <DataInput
              label={"Data Inizio"}
              name="data_inizio"
              onChange={(e) => {
                setCondominio({
                  ...condominio,
                  data_inizio: moment(e).format("YYYY-MM-DD"),
                });
              }}
              value={moment(condominio?.data_inizio)}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-blue h-min group "
              onClick={() => setOpenChangeCompagnia(true)}
            >
              <HiMiniArrowPath className="icon-blue" />
            </div>
            <div
              className="icon-container-blue h-min group "
              onClick={getStoricoCompagnieAgenzie}
            >
              <HiOutlineClock className="icon-blue" />
            </div>
            <div
              className="icon-container-green h-min group "
              onClick={handleUpdate}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
          <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>
          <div className="col-span-2 text-center text-2xl">POLIZZE</div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6 h-80">
            <DataGridPro
              rows={polizze}
              columns={columnsPolizze}
              getRowId={(row) => row.id}
              slots={{ toolbar: CustomToolbarPolizze }}
              onRowClick={(row) => {
                navigate("/Dettaglio-Polizza", {
                  state: {
                    polizza: row.row,
                  },
                });
              }}
              disableRowSelectionOnClick
            />
          </div>
          <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>
          <div className="col-span-2 text-center text-2xl">PERSONE</div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6 h-80">
            <DataGridPro
              ignoreDiacritics
              rows={personeInteressate}
              columns={columnsPersone}
              getRowId={(row) => row.id}
              slots={{ toolbar: CustomToolbar }}
              onRowClick={(row) => {
                navigate("/Dettaglio-Persona-Interessata", {
                  state: {
                    personaInteressata: row.row,
                    condominii: condominii,
                  },
                });
              }}
              disableRowSelectionOnClick
            />
          </div>
        </div>
      </div>
      <Modal
        open={openChangeCompagnia}
        handleClose={async () => {
          /* let responseCondominio = await api.get(
            "condominii/" + amministratore
          );
          setCondominii(responseCondominio.data.data); */
          setOpenChangeCompagnia(false);
        }}
      >
        <h2 className="text-2xl text-center mb-4">
          Cambio Compagnia e Agenzia
        </h2>
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 flex items-end gap-2">
            <Autocomplete
              suggestions={compagnie}
              fields={["nome"]}
              keyField="id"
              value={relCompagniaAgenzia?.id_compagnia_assicurativa}
              id="nome"
              label="Compagnia"
              name="nome"
              onChangeFunc={(e) => {
                setRelCompagniaAgenzia({
                  ...relCompagniaAgenzia,
                  id_compagnia_assicurativa: e,
                });
              }}
              icon={<HiOutlineBriefcase className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 flex items-end gap-2">
            <Autocomplete
              suggestions={agenzie}
              fields={["nome"]}
              keyField="id"
              value={relCompagniaAgenzia?.id_agenzia}
              id="nome"
              label="Agenzie"
              name="nome"
              onChangeFunc={(e) => {
                setRelCompagniaAgenzia({
                  ...relCompagniaAgenzia,
                  id_agenzia: e,
                });
              }}
              icon={<HiOutlineBuildingStorefront className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <DataInput
              label={"Data Termine Vecchio Contratto"}
              name="data_termine"
              onChange={(e) =>
                setRelCompagniaAgenzia({
                  ...relCompagniaAgenzia,
                  data_termine: moment(e).format("YYYY-MM-DD"),
                })
              }
              value={moment(relCompagniaAgenzia?.data_termine)}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <DataInput
              label={"Data Inizio Nuovo Contratto"}
              name="data_inizio"
              onChange={(e) =>
                setRelCompagniaAgenzia({
                  ...relCompagniaAgenzia,
                  data_inizio: moment(e).format("YYYY-MM-DD"),
                })
              }
              value={moment(relCompagniaAgenzia?.data_inizio)}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={handleSaveRelCompagniaAgenzia}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </Modal>
      <ModalXL
        open={openStorico}
        handleClose={async () => {
          setOpenStorico(false);
          setSelectedIds([]);
        }}
      >
        <h2 className="text-2xl text-center mb-4">
          Storico Compagnia e Agenzia
        </h2>
        <div className="w-full h-96 max-h-96">
          <DataGridPro
            rows={storico}
            columns={columnsStorico}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbarStorico }}
            getDetailPanelContent={getDetailPanelContent}
            checkboxSelection
            rowSelectionModel={selectedIds}
            onRowSelectionModelChange={(ids) => {
              setSelectedIds(ids);
            }}
            disableRowSelectionOnClick
          />
        </div>
        <ModalDelete
          open={openDeleteStorico}
          handleClose={() => setOpenDeleteStorico(false)}
          description={"il contratto selezionato"}
          handleDelete={deleteStorico}
          explanation={
            "Cancellando questo contratto si elimineranno anche le Polizze associate!"
          }
        />
      </ModalXL>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={condominio?.nome}
        handleDelete={handleDelete}
        explanation={
          "Cancellando questo Condominio si elimineranno tutte le Persone Interessate collegate ad esso!"
        }
      />
    </>
  );
};

function DetailPanelContent({ row: rowProp }) {
  const [polizze, setPolizze] = useState([]);

  const getDetailPanelHeight = useCallback(() => "auto", []);

  const getDetailPanelContent = useCallback(
    ({ row }) => <DetailPanelContentFranchigie row={row} />,
    []
  );

  useEffect(() => {
    (async () => {
      let responsePolizze = await api.get("polizze/" + rowProp.id);
      setPolizze(responsePolizze.data.data);
    })();
  }, []);

  return (
    <div className="h-48 w-full">
      <DataGridPro
        //density="compact"
        autoHeight
        columns={columnsPolizze}
        rows={polizze}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        sx={{ flex: 1 }}
        hideFooter
      />
    </div>
  );
}
function DetailPanelContentFranchigie({ row: rowProp }) {
  const [franchigie, setFranchige] = useState([]);

  useEffect(() => {
    (async () => {
      let responseGaranzie = await api.get("polizze/franchigie/" + rowProp.id);
      setFranchige(responseGaranzie.data.data);
    })();
  }, []);

  return (
    <div
      className="h-48 w-full"
      //sx={{ py: 2, height: 1, boxSizing: "border-box" }}
      //direction="column"
    >
      <DataGridPro
        //density="compact"
        autoHeight
        columns={columnsFranchigie}
        rows={franchigie}
        sx={{ flex: 1 }}
        hideFooter
      />
    </div>
  );
}

const columnsFranchigie = [
  {
    field: "garanzia",
    headerName: "Garanzia",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "valore",
    headerName: "Valore",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "doppio_valore",
    headerName: "Valore Doppio",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "franchigia_scoperto",
    headerName: "Scoperto",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <div className="w-full h-full flex items-center justify-center">
          <FaCircle
            className={`text-xl`}
            color={params.row?.franchigia_scoperto === 1 ? "green" : "red"}
          />
        </div>
      );
    },
  },
  {
    field: "percentuale",
    headerName: "Percentuale",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueFormatter: (value) => value && value + "%",
  },
  {
    field: "minimo",
    headerName: "Minimo",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];
